// src/components/Login.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../actions/userActions";
// import { ADMIN, MERCHANT, OPERATION } from "../roles";
// import store from '../store';
// import Switch from "@mui/material/Switch";

// import FormControlLabel from "@mui/material/FormControlLabel";
import {
  IconButton,
  InputAdornment,
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Snackbar,
  Alert,
} from "@mui/material";
// import { LOGIN_URL } from "./endpoint";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
// import Operation from "./Operation";
// import Stack from "immutable";

const Login = () => {
  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
    apiKey: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  // const [logInAsMerchant, setLogInAsMerchant] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  // const handleLogin = () => {
  //   const userType = 1; // or fetch from some logic
  //   dispatch(login(username, userType, password));
  //   // console.log(store.getState().auth.username)
  // };

  // const handleLogout = () => {

  //   dispatch(logout());
  // };
  const validateForm = () => {
    const errors = {};

    if (!formValues.username) {
      errors.username = "Username is required";
    }
    if (!formValues.password) {
      errors.password = "Password is required";
    }
    // if (logInAsMerchant && !formValues.apiKey) {
    //   errors.apiKey = "API Key is required";
    // }
    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(value);
    setFormValues({ ...formValues, [name]: value });
  };
  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    // console.log(formValues.username, formValues.password);

    try {
      const response = await axios.post(
        "https://gateway.arifpay.net/api/verify/support/login",
        {
          userName: formValues.username,
          password: formValues.password,
        }
      );
       const { auth, role, username } = response.data; 
      console.log(auth, role, username);

      if (auth) {
        console.log("Success Login");
        dispatch(login(formValues.username, formValues.password, role));
        if (role === 'FINANCE') {
          navigate("/Finance");
        } else {
          navigate("/Operation");
        }
      } else {
        setSnackbarMessage("Invalid username or password");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
      setSnackbarMessage("An error occurred during login");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };
  // const handleSwitchChange = (event) => {
  //   setLogInAsMerchant(event.target.checked);
  // };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
   
      <Container maxWidth="xs" sx={{ marginTop: "250px"}}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: theme.spacing(4),

          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: theme.palette.background.paper,
          marginTop: theme.spacing(8),
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Login
        </Typography>
        {/* <FormControlLabel
          control={
            <Switch
              checked={logInAsMerchant}
              onChange={handleSwitchChange}
              defaultChecked={logInAsMerchant} // Ensure defaultChecked reflects initial state
            />
          }
          label={logInAsMerchant ? "Login As Merchant" : "Login As Arif"}
        /> */}
        {/* 
        <Switch defaultChecked />
        <p color="warning">log in as merchant </p> */}
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <TextField
            fullWidth
            label="Username"
            variant="standard"
            margin="normal"
            name="username"
            value={formValues.username}
            error={!!errors.username}
            helperText={errors.username}
            onChange={handleInputChange}
          />

          <TextField
            variant="standard"
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            margin="normal"
            fullWidth
            error={!!errors.password}
            helperText={errors.password}
            value={formValues.password}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* {logInAsMerchant && (
            <TextField
              fullWidth
              label="API KEY"
              variant="standard"
              margin="normal"
              name="apiKey"
              value={formValues.apiKey}
              error={!!errors.apiKey}
              helperText={errors.apiKey}
              onChange={handleInputChange}
            />
          )} */}
          <Button
            type="standard"
            submit
            variant="contained"
            color="success"
            fullWidth
            sx={{ mt: 3 }}
            AuthProvider={false}
          >
            Login
          </Button>
        </form>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
    
  );
};

export default Login;
