import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Box,
  Chip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Snackbar,
  Alert,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Sidebar from "../Component/Bar/Sidebar";
import Header from "../Component/Header";
import CloseIcon from "@mui/icons-material/Close";

const Finance = () => {
  const [currentView, setCurrentView] = useState("pending");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [token, setToken] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [searchQuery, setSearchQuery] = useState("");
  const [updatingId, setUpdatingId] = useState(null);
  const cancelTokenRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Login to get the token
        const loginResponse = await axios.post(
          "https://backend-service.arifpay.net/api/Account/Login",
          {
            emailOrPhone: "251944444444",
            password: "@rifPayadmin1",
            captchaResponse: "true",
          }
        );

        const jwtToken = loginResponse.data.data.jwttoken;
        setToken(jwtToken);

        // Fetch payment requests using the token
        const paymentResponse = await axios.get(
          "https://backend-service.arifpay.net/api/PaymentRequest/GetSettlementRequests",
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        setData(paymentResponse.data);

        setSnackbarMessage("Data fetched successfully!");
        setSnackbarSeverity("success");
      } catch (error) {
        console.error("Error fetching data:");
        setSnackbarMessage("Error fetching data. Please try again.");
        setSnackbarSeverity("error");
      } finally {
        setSnackbarOpen(true); // Show Snackbar regardless of success or error
      }
    };

    if (data.length === 0) {
      fetchData();
    }
  }, []);
  /* here Add Parameter for status*/
  const handleUpdateStatus = async (paymentRequestid, status) => {
    setUpdatingId(paymentRequestid);
    cancelTokenRef.current = axios.CancelToken.source();

    try {
      const url =
        "https://backend-service.arifpay.net/api/PaymentRequest/UpdatePaymentRequestStatus";
      const params = {
        paymentRequestid: paymentRequestid,
        /* here don't use only 1 for every status update use the status passed via the new parameter*/
        status: status,
      };

      const response = await axios.put(url, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: cancelTokenRef.current.token,
      });

      if (response.status === 200) {
        if (response.data.isPassed) {
          /* here update the status of the payment request in the data array*/
          if(status === 1){
            setData((prevData) => {
                return prevData.map((item) =>
                  item.id === paymentRequestid
                    ? { ...item, status: "Completed" }
                    : item
                );
              });
          }else{
            setData((prevData) => {
                return prevData.map((item) =>
                  item.id === paymentRequestid
                    ? { ...item, status: "Pending" }
                    : item
                );
              });
          }
         
          setSnackbarMessage("Status Updated successfully");
          setSnackbarSeverity("success");
        } else {
          setSnackbarMessage(`Update Failed ${response.data.message}`);
          setSnackbarSeverity("warning");
        }
      } else {
        setSnackbarMessage("Failed to update status");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
        setSnackbarMessage("Update canceled");
        setSnackbarSeverity("info");
      } else {
        console.error("Error updating status:", error);
        setSnackbarMessage("An error occurred while updating status");
        setSnackbarSeverity("error");
      }
    } finally {
      setSnackbarOpen(true);
      setUpdatingId(null);
      cancelTokenRef.current = null;
    }
  };

  const handleCancelUpdate = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Operation canceled by the user.");
    }
  };

  const handleSort = (column) => {
    let direction = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: column, direction });
  };

  // <TableCell>{new Date(row.paymentDate).toLocaleDateString()}</TableCell>
  //                                 <TableCell>{row.paymentMethod}</TableCell>
  //                                 <TableCell>{row.accountNumber}</TableCell>
  //                                 <TableCell>{row.amount} {row.currency}</TableCell>
  //                                 <TableCell>{row.status}</TableCell>

  const filteredData = data.filter((item) => {
    const matchesView =
      currentView === "pending"
        ? item.status === "Pending"
        : item.status === "Completed";
    const matchesSearch =
      item.paymentMethod.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.accountNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.amount
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item.merchants.businessName
        .toLowerCase()
        .includes(searchQuery.toLocaleLowerCase());

    return matchesView && matchesSearch;
  });
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      return sortConfig.direction === "asc"
        ? aValue < bValue
          ? -1
          : 1
        : aValue > bValue
        ? -1
        : 1;
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
        <Header title="Finance" subtitle="" />
        <Box sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              marginTop: 1,
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Chip
              label={`Pending (${
                sortedData.filter((item) => item.status === "Pending").length
              })`}
              color={currentView === "pending" ? "warning" : "default"}
              onClick={() => setCurrentView("pending")}
              style={{ cursor: "pointer", fontWeight: "bold" }}
            />
            <Chip
              label={`Completed (${
                sortedData.filter((item) => item.status === "Completed").length
              })`}
              color={currentView === "completed" ? "secondary" : "default"}
              onClick={() => setCurrentView("completed")}
              style={{ cursor: "pointer", fontWeight: "bold" }}
            />
            <TextField
              variant="standard"
              label="Search"
              placeholder="Search ..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ marginLeft: "auto", marginRight: 2, marginBottom: 2 }}
            />
          </Box>
        </Box>

        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor:
                  currentView === "completed" ? "#4ED45B" : "#F3C23C",
              }}
            >
              <TableRow>
                <TableCell
                  onClick={() => handleSort("id")}
                  style={{ cursor: "pointer" }}
                >
                  ID
                </TableCell>
                <TableCell
                // onClick={() => handleSort('id')} style={{ cursor: 'pointer' }}
                >
                  Merchant Name
                </TableCell>
                <TableCell
                  onClick={() => handleSort("paymentDate")}
                  style={{ cursor: "pointer" }}
                >
                  Payment Date
                </TableCell>
                <TableCell
                  onClick={() => handleSort("paymentMethod")}
                  style={{ cursor: "pointer" }}
                >
                  Payment Method
                </TableCell>
                <TableCell
                  onClick={() => handleSort("accountNumber")}
                  style={{ cursor: "pointer" }}
                >
                  Account Number
                </TableCell>
                <TableCell
                  onClick={() => handleSort("amount")}
                  style={{ cursor: "pointer" }}
                >
                  Amount
                </TableCell>
                <TableCell>Status</TableCell>
                {/* here The Action Column must appear for both pending and completed transfers*/}
                {/* {currentView === 'pending' && <TableCell>Actions</TableCell>} */}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.merchants.businessName}</TableCell>
                    <TableCell>
                      {new Date(row.paymentDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{row.paymentMethod}</TableCell>
                    <TableCell>{row.accountNumber}</TableCell>
                    <TableCell>
                      {row.amount.toLocaleString("en-US")} {row.currency}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>

                    {/* here for pending call teh handleUpdateStatus with row.id and 1 but when the row.status is completed then the the handleUpdateStatus with row.id and 0*/}
                    {row.status === "Pending" ? (
                      <TableCell>
                        {updatingId === row.id ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Button
                            onClick={() => handleUpdateStatus(row.id, 1)}
                            sx={{
                              borderBottom: 1,
                              borderColor: "primary.main",
                              transition: "all 0.3s ease",
                              color: "#3f51b5",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = "#F3C23C";
                              e.target.style.borderColor = "#3f51b5";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.borderColor = "primary.main";
                              e.target.style.backgroundColor = "transparent";
                            }}
                          >
                            Update
                          </Button>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell>
                        {updatingId === row.id ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Button
                            onClick={() => handleUpdateStatus(row.id,0)}
                            sx={{
                              borderBottom: 1,
                              borderColor: "primary.main",
                              transition: "all 0.3s ease",
                              color: "#3f51b5",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = "#F3C23C";
                              e.target.style.borderColor = "#3f51b5";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.borderColor = "primary.main";
                              e.target.style.backgroundColor = "transparent";
                            }}
                          >
                            Revert  
                          </Button>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Positioning the Snackbar
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default Finance;
