import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { ADMIN, FINANCE,OPERATION } from "../roles";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userType = useSelector((state) => state.auth.userType);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (userType === ADMIN) {
    return children;
  }

  if (userType === FINANCE) {
    if (location.pathname === "/Finance") {
      return children;
    } else {
      return <Navigate to="/Finance" replace />;
    }
  }

  if (userType === OPERATION) {
    if (location.pathname === "/Operation") {
      return children;
    } else {
      return <Navigate to="/Operation" replace />;
    }
  }

  
  return <Navigate to="/Operation" replace />;
};

export default PrivateRoute;
